import { createSlice, createAsyncThunk, miniSerializeError, SerializedError } from '@reduxjs/toolkit';
import { resetStore } from 'src/store';

import { ServerErrorAdapter } from '@itm/shared-frontend/lib/utils';

import { getCountryCodes } from 'src/api/clientPortal/adminContact';

import { ServerError } from 'src/types';

type AdminContactState = {
  isLoading: boolean;
  countryCodeList: string[];
  serverErrorMessages: string[];
};

const serializeError = (e: unknown): SerializedError => {
  const message = new ServerErrorAdapter(e as ServerError).combine().join('\n  ');
  return miniSerializeError({ message });
};

export const fetchCountryCodeList = createAsyncThunk(
  'adminContact/fetchCountryCodeList',
  async () => {
    const res = await getCountryCodes();
    return res.data;
  },
  { serializeError },
);

const initialState: AdminContactState = {
  isLoading: false,
  countryCodeList: [],
  serverErrorMessages: [],
};

const adminContactSlice = createSlice({
  name: 'company',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCountryCodeList.pending, (state: AdminContactState) => {
      state.isLoading = true;
    });
    builder.addCase(fetchCountryCodeList.fulfilled, (state: AdminContactState, action) => {
      state.isLoading = false;
      state.countryCodeList = action.payload;
    });
    builder.addCase(fetchCountryCodeList.rejected, (state: AdminContactState, action) => {
      state.isLoading = false;
      state.serverErrorMessages = action.error.message ? [action.error.message] : [];
    });

    // Cleanup
    builder.addCase(resetStore, (state: AdminContactState) => {
      Object.assign(state, initialState);
    });
  },
});

export default adminContactSlice.reducer;
