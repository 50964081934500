import { GlobalRole, SearchOrder } from '@itm/shared-frontend/lib/types';
export * from './dataGateway';
export * from './auth';

export type {
  GlobalRole,
  TableSortParams,
  TenantBrandingResponse,
  SelectOption,
  ServerValidationError,
  ServerValidationResponse,
  ServerError,
  FieldErrors,
  ServerFormErrors,
  ExternalFilters,
  TanstackTableChangeParams,
} from '@itm/shared-frontend/lib/types';

export enum TargetPlatform {
  AWS = 'AWS',
  AZURE = 'AZURE',
}

export enum ClientPortalRole {
  SuperAdmin = 'SuperAdmin',
  Support = 'Support',
  Analyst = 'ClientPortal_Analyst',
  Viewer = 'ClientPortal_Viewer',
  ClientAdmin = 'ClientPortal_ClientAdmin',
  Manager = 'ClientPortal_Manager',
}

export enum DataGatewayRole {
  SuperAdmin = 'SuperAdmin',
  Support = 'Support',
  Analyst = 'DataGateway_Analyst',
  Viewer = 'DataGateway_Viewer',
  ClientAdmin = 'DataGateway_ClientAdmin',
  Manager = 'DataGateway_Manager',
}

export enum UserStatus {
  Invited = 'Invited',
  Active = 'Active',
  Deactivated = 'Deactivated',
  SetupIncomplete = 'SetupIncomplete',
}

export enum UserSQLPermissions {
  Enabled = 'Enabled',
  Disabled = 'Disabled',
}

export enum ContactPreference {
  Website = 'Website',
  Email = 'Email',
  Phone = 'Phone',
  Mail = 'Mail',
}
export enum PhoneNumberType {
  Main = 'Main',
  NonUK = 'NonUK',
  SMS = 'SMS',
  Welsh = 'Welsh',
  WhatsApp = 'WhatsApp',
}

export enum CompanyStatus {
  None = 'None',
  SchemesAvailable = 'SchemesAvailable',
  ProductsAvailable = 'ProductsAvailable',
}

export enum DataSeparationType {
  Default = 'Default',
  Company = 'Company',
}

export enum PaymentMethod {
  DirectDebit = 'DirectDebit',
  NoDirectDebit = 'NoDirectDebit',
}

export enum BulkUploadAction {
  Update = 'Update',
  Create = 'Create',
}

export enum BulkUploadArea {
  Company = 'Company',
  Scheme = 'Scheme',
  Employer = 'Employer',
  StrategicPartner = 'StrategicPartner',
  User = 'User',
  Section = 'Section',
}

export enum SourceFileStatus {
  InProgress = 'InProgress',
  Uploaded = 'Uploaded',
  Completed = 'Completed',
  Failed = 'Failed',
}

export enum Severity {
  Error = 'Error',
  Warning = 'Warning',
  Info = 'Info',
}

export enum Action {
  None = 'None',
  Create = 'Create',
  Update = 'Update',
}

export enum Area {
  None = 'None',
  Company = 'Company',
  Scheme = 'Scheme',
  Employer = 'Employer',
  Section = 'Section',
  StrategicPartner = 'StrategicPartner',
}

export enum CreatedStatus {
  Created = 'Created',
  NotCreated = 'NotCreated',
  Updated = 'Updated',
  PartiallyCreated = 'PartiallyCreated',
}

export enum UserAccountState {
  AccountCreated = 'AccountCreated',
  MFAReset = 'MFAReset',
  PasswordChanged = 'PasswordChanged',
}

export type CompanyResponse = {
  id: string;
  tenantId: string;
  name: string | null;
  statuses: CompanyStatus[];
  logoUrl: string | null;
  database: DataSeparationType;
  updatedDate: string | null;
  createdDate: string;
};

export type AdminContactResponse = {
  id: string;
  displayName: string | null;
  administratorName: string | null;
  administratorReference: string | null;
  website: string | null;
  email: string | null;
  address1: string | null;
  address2: string | null;
  address3: string | null;
  address4: string | null;
  address5: string | null;
  postcode: string | null;
  postalName: string | null;
  countryCode: string | null;
  createdDate: string;
  updatedDate: string | null;
  contactPreference: ContactPreference;
  administratorPhones: AdministratorPhoneResponse[] | null;
};

export type CompanyAdminDataSourceResponse = {
  id: string;
  adminDataSourceId: string;
  name: string;
};

export type CompanyDetailsResponse = CompanyResponse & {
  accessToAllSchemesEnabled: boolean;
  adminContact: AdminContactResponse;
  alternativeAdminContact: AdminContactResponse | null;
  companyAdminDataSources: CompanyAdminDataSourceResponse[] | null;
};

export type AuditResponse = {
  id: string;
  userName: string;
  userId: string;
  eventType: string;
  entityType: string;
  dateTime: string;
  oldValues: string;
  newValues: string;
  affectedColumn: string;
  entityId: string;
};

export type GetAuditResponse = {
  totalCount: number;
  auditRecords: AuditResponse[];
};

export type CompanySearchResponse = {
  totalCount: number;
  companies: CompanyResponse[];
};

export type AdministratorPhoneDto = {
  id?: string | null;
  phoneNumber: string | null;
  order?: number;
  phoneNumberType: PhoneNumberType;
};

export enum AdminContactType {
  Primary = 'Primary',
  Alternative = 'Alternative',
}

export type AdminContactModel = {
  displayName?: string;
  administratorName?: string;
  administratorReference?: string;
  contactPreference?: ContactPreference;
  website?: string;
  email?: string;
  address1?: string;
  address2?: string;
  address3?: string;
  address4?: string;
  address5?: string;
  postcode?: string;
  postalName?: string;
  countryCode?: string;
  administratorPhones?: AdministratorPhoneDto[];
};

export type CreateCompanyModel = {
  companyName: string;
  tenantId: string;
  dataSeparationRequired: boolean;
  adminDataSourceNames: string[];
  adminContact: AdminContactModel;
  alternativeAdminContact?: AdminContactModel;
};

export type UpdateCompanyModel = Omit<CreateCompanyModel, 'dataSeparationRequired'> & {
  companyId: string;
};

export type AddProductPermissionModel = {
  userId: string;
  companyId: string;
  schemeId: string;
  productId: string;
  productRoleId: string;
};

export type AddEmployerPermissionModel = {
  schemeId: string;
  userId: string;
  employerId: string;
};

export type BulkAddEmployerPermissionModel = {
  schemeId: string;
  userId: string;
  employerIds: string[];
};

export type BulkRemoveEmployerPermissionModel = {
  userEmployerIds: string[];
};

export type SchemeIdModel = {
  id: string;
};

export type ProductIdModel = {
  id: string;
  schemes?: SchemeIdModel[];
};

export type CompanyIdModel = {
  id: string;
  products?: ProductIdModel[];
};

export type AddBulkPermissionModel = {
  userId: string;
  companies: CompanyIdModel[];
};

export type DeletePermissionModel = {
  userId: string;
  companyId: string;
  productId: string;
  userProductRoleId: string;
  schemeId: string;
};

export type DowngradePermissionModel = {
  userId: string;
  companyId: string;
  productId: string;
  productRoleId: string;
};

export type BasePermissionModel = {
  userId: string;
  companyId: string;
  productId: string;
};

export type PermissionModel = {
  userId: string;
  companyId: string;
  productId: string;
  schemeId: string;
};

export type SchemeIdDto = {
  id: string;
};

export type ProductIdDto = {
  productId: string;
  schemes: SchemeIdDto[];
};

export type CompanyIdDto = {
  id: string;
  products: ProductIdDto[];
};

export type BulkAssignSchemeProductPermissions = {
  userId: string;
  company: CompanyIdDto;
};

export type BulkAssignEmployerSchemeProductPermissions = {
  userId: string;
  schemeId: string;
};

export type BulkAssignUserProductModel = {
  userId: string;
  companyId: string;
  productIds: string[];
};

export type UserPermissionResponse = {
  userId: string;
  companyId: string;
  companyName: string;
  schemeId: string;
  schemeName: string;
  productId: string;
  productName: string;
  productRoleId: string;
  productRoleName: GlobalRole;
  userProductRoleId: string;
  assignmentDate: string;
};

export type UserEmployerPermissionResponse = {
  userEmployerId: string;
  userId: string;
  employerId: string;
};

export type CreateSupportUserModel = {
  title: string;
  firstName: string;
  lastName: string;
  email: string;
  mobile?: string;
};

export type CreateUserModel = CreateSupportUserModel & {
  companyId: string;
};

export type CheckUserModel = {
  email: string;
};

export type AddClientPortalModel = {
  userId: string;
  clientPortalRoleId: string;
};

export type UserDto = {
  id: string;
  title: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  mobile: string | null;
  status: UserStatus;
  updatedDate: string;
  createdDate: string;
};

export type UserRoleResponse = {
  productRoleId: string;
  roleName: ClientPortalRole | null;
};

export type CurrentUserResponse = {
  hasSFTPAccess: boolean;
  companies: CompanyResponse[];
  clientPortalRole: UserRoleResponse;
  tenants: TenantResponse[];
} & UserDto;

export type CompanyAccessResponse = {
  id: string;
  hasDefaultSchemeAccess: boolean;
};

export type UserDetailResponse = {
  hasSFTPAccess: boolean;
  companies: CompanyAccessResponse[];
  clientPortalRole: UserRoleResponse;
  accountState: UserAccountState;
} & UserDto;

export type UserAuthChallenge = {
  name: string;
  response: string;
};

export type UserAuthEvent = {
  eventType: string | null;
  eventResult: string | null;
  device: string | null;
  ip: string | null;
  createDate: string | null;
};

export type GetUserDetailsResponseDto = {
  userIdentityId: string | null;
  userId: string | null;
  status: UserStatus | null;
  userAccountState: UserAccountState | null;
  roles: string | null;
  name: string | null;
  email: string | null;
  username: string | null;
  emailVerified: boolean;
  enabled: boolean;
  twoFactorEnabled: boolean;
  createdDate: string;
  updatedDate: string | null;
};

export type GetUserAuthEventsResponse = {
  totalCount: number;
  records: UserAuthEvent[];
};

export type UserCompanyModel = {
  userId: string;
  companyId: string;
};

export type RequestSearchParams = {
  take?: number;
  skip?: number;
  search?: string;
  orderField?: string;
  orderBy?: SearchOrder;
};

export type UserSearchResponse = {
  hasSFTPAccess: boolean;
  clientPortalRole?: UserRoleResponse;
} & UserDto;

export type UserSearchPagedResponse = {
  totalCount: number;
  items: UserSearchResponse[];
};

export type UpdateUserModel = {
  id: string;
  title: string;
  firstName: string;
  lastName: string;
  mobile?: string;
  email: string;
};

export type ProductSchemeDto = {
  id: string;
  assignmentDate: string;
  schemeName: string;
  companyId: string;
};

export type ProductRolesDto = {
  id: string;
  name: string;
};
export type CompanyProductDto = {
  id: string;
  companyId: string;
};
export type UserProductsDto = {
  id: string;
  userId: string;
  schemeId: string;
};

export type ProductRoleResponse = {
  productRoleId: string;
  roleName: string;
};

export type ProductResponse = {
  id: string;
  name: string | null;
  domainPrefix: string | null;
  logoUrl: string | null;
  url: string | null;
  description: string | null;
  updatedDate: string;
  createdDate: string;
};

export type ShortSchemeResponse = {
  id: string;
  name: string;
};

export type ProductSchemeResponse = {
  id: string;
  name: string | null;
  logoUrl: string | null;
  schemes: ShortSchemeResponse[];
};

export type TenantProductResponse = {
  id: string;
  name: string | null;
  logoUrl: string | null;
  url: string | null;
  description: string | null;
  assigned: boolean;
  promote: boolean;
  domainPrefix: string | null;
};

export type CreateProductModel = {
  id: string;
  productName: string;
  description: string;
};

export type UpdateProductModel = {
  productId: string;
  productName: string;
  description: string;
};

export type AddRoleModel = {
  productId: string;
  roleName: string;
};

export type SchemeProductModel = {
  schemeId: string;
  productId: string;
};

export type CompanyProductModel = {
  companyId: string;
  productId: string;
};

export enum AdditionalSchemeType {
  OTH = 'OTH',
  FOR = 'FOR',
}

export type SchemeResponse = {
  id: string;
  companyId: string;
  schemeSrcId: string | null;
  name: string;
  friendlyName: string | null;
  createdDate: string;
  updatedDate: string | null;
  adminContact: AdminContactResponse | null;
  alternativeAdminContact: AdminContactResponse | null;
  urlSip: string | null;
  urlCostsandcharges: string | null;
  urlImplstatement: string | null;
  urlAnnualreport: string | null;
  psrNumber: number | null;
  pstrNumber: string | null;
  additionalName: string | null;
  additionalType: AdditionalSchemeType | null;
};

export type SchemeDetailsResponse = SchemeResponse & {
  companyAdminDataSourceId: string | null;
  adminDataSourceName: string | null;
};

export type SchemeAssignedToCompanyResponse = {
  id: string;
  companyId: string;
  name: string | null;
  friendlyName: string | null;
  additionalName: string | null;
  adminDataSourceName: string | null;
  schemeSrcId: string | null;
  contactPreference: ContactPreference | null;
  updatedDate: string | null;
  createdDate: string;
};

export type CreateSchemeModel = {
  companyId: string;
  schemeSrcId?: string;
  companyAdminDataSourceId: string;
  name: string;
  friendlyName?: string;
  adminContact?: AdminContactModel;
  alternativeAdminContact?: AdminContactModel | null;
  urlCostsandcharges?: string;
  urlSip?: string;
  urlImplstatement?: string;
  urlAnnualreport?: string;
  psrNumber?: number;
  pstrNumber?: string;
  additionalName?: string;
  additionalType?: AdditionalSchemeType;
};

export type UpdateSchemeModel = Omit<CreateSchemeModel, 'companyId'> & {
  schemeId: string;
};

export enum PensionType {
  AVC = 'AVC',
  CB = 'CB',
  CDC = 'CDC',
  DB = 'DB',
  DC = 'DC',
  HYB = 'HYB',
}

export enum PensionStatus {
  IWU = 'IWU',
  IPPF = 'IPPF',
}

export type SectionResponse = {
  id: string;
  schemeId: string;
  name: string | null;
  pensionType: PensionType;
  pensionStatus: PensionStatus | null;
  sectionSrcId: string | null;
  urlCostsandcharges: string | null;
  urlSip: string | null;
  urlImplstatement: string | null;
  urlAnnualreport: string | null;
  adminContact: AdminContactResponse | null;
  alternativeAdminContact: AdminContactResponse | null;
};

export type CreateSectionModel = {
  name: string;
  schemeId: string;
  sectionSrcId: string;
  pensionType: PensionType;
  pensionStatus?: PensionStatus;
  urlCostsandcharges?: string;
  urlSip?: string;
  urlImplstatement?: string;
  urlAnnualreport?: string;
  adminContact?: AdminContactModel;
  alternativeAdminContact?: AdminContactModel;
};

export type UpdateSectionModel = Omit<CreateSectionModel, 'schemeId'> & {
  id: string;
};

export type SectionExtendedResponse = {
  id: string;
  name: string | null;
  pensionType: PensionType;
  pensionStatus: PensionStatus;
  dataSourceName: string | null;
  sectionSrcId: string | null;
  contactPreference: ContactPreference;
  createdDate: string;
  updatedDate: string | null;
};

export type SectionExtendedSearchResponse = {
  totalCount: number;
  items: SectionExtendedResponse[];
};

export type ContactInfoResponse = {
  email: string;
};

export type ClientPortalInfoResponse = {
  version: string | null;
  description?: string;
};

export type AdminDataSourceResponse = {
  id: string;
  name: string;
};

export type AdministratorPhoneResponse = {
  id: string;
  administratorId: string;
  order: number;
  phoneNumber: string;
  phoneNumberType: PhoneNumberType;
};

export type AdministratorPhoneModel = {
  phoneNumber: string;
  phoneNumberType: PhoneNumberType;
};

export enum TenantStatus {
  None = 'None',
  DetailsIncomplete = 'DetailsIncomplete',
  BrandingIncomplete = 'BrandingIncomplete',
  AssignProductsIncomplete = 'AssignProductsIncomplete',
  Available = 'Available',
}

export type TenantResponse = {
  id: string;
  name: string | null;
  domain: string | null;
  logoUrl: string | null;
};

export type TenantShortResponse = {
  id: string;
  name: string | null;
};

export type DomainResponse = {
  id: string;
  name: string | null;
};

export type CreateTenantDto = {
  name: string;
  domain: string;
};

export type UpdateTenantDto = {
  name: string;
  domain: string;
};

export type UpdateTenantBrandingModel = {
  termsAndConditions?: string;
  colorPrimary: string;
  colorPrimaryActive: string;
  colorPrimaryHover: string;
  colorPrimaryInvert: string;
  colorSecondary: string;
  colorSecondaryActive: string;
  colorSecondaryHover: string;
  colorSecondaryInvert: string;
};

export type UpdateTenantProductDto = {
  promote: boolean;
};

export type TenantSearchResponse = {
  id: string;
  name: string | null;
  domain: string | null;
  status: TenantStatus;
  createdDate: string;
  updatedDate: string | null;
};

export type TenantSearchPagedResponse = {
  totalCount: number;
  items: TenantSearchResponse[];
};

export type EmployersResponse = {
  id: string;
  name: string;
  address1: string;
  address2: string;
  address3: string;
  address4: string;
  address5: string;
  postcode: string;
  email: string;
  hasPaymentPlan: boolean;
  schemeId: string;
  sectionId: string | null;
  strategicPartnerId: string | null;
  updatedDate: string | null;
  createdDate: string;
  epsr: string;
  sectionName: string;
  paymentMethod: string | null;
  companiesHouseNumber: string;
  charityNumber: string;
  schemeName: string;
  strategicPartnerName: string;
};

export type EmployerDetailsByEmployerAndSchemeModel = {
  employerId: string;
  schemeId: string;
};

export type UpdateEmployerModel = {
  id: string;
  name: string;
  address1: string;
  address2?: string;
  address3?: string;
  address4?: string;
  address5?: string;
  postcode: string;
  email: string;
  hasPaymentPlan: boolean;
  schemeId: string;
  sectionId: string;
  epsr?: string;
  strategicPartnerId?: string;
  paymentMethod: string;
  companiesHouseNumber?: string;
  charityNumber?: string;
};

export type StrategicPartnerResponse = {
  id: string;
  name: string;
};

export type SectionBySchemeIdResponse = {
  schemeId: string;
  schemeName: string;
};

export type SearchSourceFileResponse = {
  sourceFileId: string;
  fileName: string | null;
  area: Area;
  action: Action;
  actionText: string | null;
  areaText: string | null;
  uploadedDate: string;
  uploadedBy: string | null;
  status: SourceFileStatus;
  statusText: string | null;
};

export type SearchSourceFileResponsesWithCount = {
  totalCount: number;
  items: SearchSourceFileResponse[];
};

export type SourceFileResponse = {
  id: string;
  name: string | null;
  fileType: string | null;
  uploadedDate: string;
  schemeName: string | null;
  strategicPartnerName: string | null;
  uploadedByUserName: string | null;
  status: SourceFileStatus;
  statusText: string | null;
  arn: string | null;
  filePathWithName: string | null;
  fileNameId: string;
  uploadedByUserEmail: string | null;
  totalRecords: number;
  totalErrors: number;
  totalWarnings: number;
  totalProcessed: number;
  mappingType: string | null;
  action: string | null;
  area: string | null;
  bulkUploadStatuses: BulkUploadStatusDto[];
  sectionUploadStatuses: SectionUploadStatusDto[] | null;
  schemeUploadStatuses: SchemeUploadStatusDto[] | null;
  employerUploadStatuses: EmployerUploadStatusDto[] | null;
  updateSectionUploadStatusDto: UpdateSectionUploadStatusDto[] | null;
  companyUploadStatuses: CompanyUploadStatusDto[] | null;
  userUploadStatuses: UserUploadStatusDto[] | null;
  strategicPartnerStatuses: StrategicPartnerStatusDto[] | null;
};

export type BulkUploadStatusDto = {
  id: string;
  companyName: string | null;
  companyId: string | null;
  employerName: string | null;
  schemeName: string | null;
  sectionName: string | null;
  strategicPartnerName: string | null;
  epsr: string | null;
  hasError: boolean;
  sourceFileId: string;
  messages: string | null;
  propertyName: string | null;
  severity: Severity;
  status: string | null;
  tenantName: string | null;
  adminDataSource: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  sectionSrcId: string | null;
  schemeSrcId: string | null;
};

export type SectionUploadStatusDto = {
  companyName: string | null;
  schemeName: string | null;
  adminDataSource: string | null;
  sectionSrcId: string | null;
  sectionName: string | null;
  hasError: boolean;
  messages: string | null;
  status: string | null;
};

export type SchemeUploadStatusDto = {
  companyName: string | null;
  schemeName: string | null;
  adminDataSource: string | null;
  schemeSrcId: string | null;
  hasError: boolean;
  messages: string | null;
  status: string | null;
};

export type EmployerUploadStatusDto = {
  companyName: string | null;
  schemeName: string | null;
  employerName: string | null;
  epsr: string | null;
  hasError: boolean;
  messages: string | null;
  status: string | null;
};

export type UpdateSectionUploadStatusDto = {
  companyName: string | null;
  schemeName: string | null;
  adminDataSource: string | null;
  sectionSrcId: string | null;
  sectionName: string | null;
  hasError: boolean;
  messages: string | null;
  status: string | null;
};

export type CompanyUploadStatusDto = {
  companyName: string | null;
  companyId: string | null;
  tenantName: string | null;
  adminDataSource: string | null;
  hasError: boolean;
  messages: string | null;
  status: string | null;
};

export type UserUploadStatusDto = {
  companyName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  schemeName: string | null;
  hasError: boolean;
  messages: string | null;
  status: string | null;
};

export type StrategicPartnerStatusDto = {
  schemeName: string | null;
  strategicPartnerName: string | null;
  hasError: boolean;
  messages: string | null;
  status: string | null;
};

export enum DataAccessDurationType {
  Hours = 'Hours',
  Days = 'Days',
}

export enum UserDataPermissionStatus {
  Pending = 'Pending',
  Active = 'Active',
  Expired = 'Expired',
  Rejected = 'Rejected',
  Revoked = 'Revoked',
}
export enum UserDataPermissionRequestStatus {
  Pending = 'Pending',
  Approved = 'Approved',
  Rejected = 'Rejected',
  Revoked = 'Revoked',
}

export type DataAccessPermissionPagedResponse = {
  totalCount: number;
  items: DataAccessPermissionResponse[];
};

export type DataAccessPermissionResponse = {
  companyId: string;
  companyName: string | null;
  userDataPermissionRequestId: string;
  userDataPermissionId: string;
  reviewerUserId: string | null;
  reviewerUserFullName: string | null;
  status: UserDataPermissionStatus;
  reviewDate: string | null;
  duration: number;
  expiryDate: string | null;
};

export type GetDataAccessDetailsRequestModel = {
  reviewerName: string | null;
  requestDate: string;
  reviewDate: string | null;
  requestDescription: string | null;
  reviewDescription: string | null;
  duration: number;
  status: UserDataPermissionRequestStatus;
  expiryDate: string | null;
  sentTo: string | null;
};

export type GetDataAccessDetailsSchemeModel = {
  id: string;
  name: string | null;
};

export type GetDataAccessDetailsProductModel = {
  id: string;
  productName: string | null;
  schemes: GetDataAccessDetailsSchemeModel[];
};

export type GetDataAccessDetailsResponse = {
  companyName: string | null;
  createdBy: string | null;
  accessExpiryDate: string;
  status: UserDataPermissionStatus;
  requests: GetDataAccessDetailsRequestModel[] | null;
  products: GetDataAccessDetailsProductModel[] | null;
};

export enum DataAccessRequestStatusDto {
  Pending = 'Pending',
  Rejected = 'Rejected',
  Active = 'Active',
  Revoked = 'Revoked',
  Expired = 'Expired',
}

export type DataAccessRequestDto = {
  id: string;
  userName: string | null;
  reviewerName: string | null;
  requestDate: string;
  expiryDate: string | null;
  reviewDate: string | null;
  totalHours: number;
  status: DataAccessRequestStatusDto;
};

export type DataAccessPagedRequestDto = {
  totalCount: number;
  items: DataAccessRequestDto[];
};

export type GetDataAccessRequestDetailsResponse = {
  id: string;
  companyName: string | null;
  accessRequestedBy: string | null;
  status: UserDataPermissionRequestStatus;
  requestDate: string;
  reviewDate: string | null;
  expiryDate: string | null;
  duration: number;
  requestDescription: string | null;
  reviewDescription: string | null;
  reviewerName: string | null;
  sentTo: string | null;
  products: GetDataAccessDetailsProductModel[] | null;
};

export type SchemeFromProductDto = {
  schemeId: string;
  schemeName?: string;
};

export type ProductDto = {
  productId: string;
  productName?: string;
  schemes: SchemeFromProductDto[];
};

export type CreateDataAccessDto = {
  companyId: string;
  companyName?: string;
  accessDuration: number;
  reason: string;
  products: ProductDto[];
};

export type ApproveDataAccessRequestDto = {
  totalHours: number;
};

export type RejectDataAccessRequestDto = {
  reason: string;
};

export enum DataRetentionPolicyStatus {
  Draft = 'Draft',
  Active = 'Active',
  ApprovalPending = 'ApprovalPending',
}
export enum DataRetentionPolicyType {
  Default = 'Default',
  Alternative = 'Alternative',
}

export type DataRetentionSchemeResponse = {
  id: string;
  name: string | null;
};

export type DataRetentionResponse = {
  id: string;
  versionId: string;
  name: string | null;
  status: DataRetentionPolicyStatus;
  type: DataRetentionPolicyType;
  version: number;
  createdDate: string;
  updatedDate: string | null;
  isEditable: boolean;
};

export type DataRetentionPagedResponse = {
  totalCount: number;
  items: DataRetentionResponse[] | null;
};

export enum DataRetentionProductRuleType {
  TimeFrame = 'TimeFrame',
  Files = 'Files',
}

export type DataRetentionProductResponse = {
  id: string;
  productId: string;
  order: number;
  name: string | null;
  schemes: DataRetentionSchemeResponse[];
  rules: DataRetentionProductRuleResponse[];
};

export type DataRetentionProductRuleResponse = {
  id: string;
  order: number;
  dataRetentionCategory: string | null;
  type: DataRetentionProductRuleType;
  period: number;
  createdDate: string;
};

export enum DataRetentionPolicyApprovalStatus {
  Draft = 'Draft',
  Approved = 'Approved',
  Rejected = 'Rejected',
  ApprovalPending = 'ApprovalPending',
}

export type DataRetentionPolicyApprovalResponse = {
  dataRetentionPolicyVersionId: string;
  reviewerUserId: string | null;
  status: DataRetentionPolicyApprovalStatus;
  updatedDate: string | null;
  createdDate: string;
  reviewerComment: string | null;
};

export type DataRetentionDetailsResponse = {
  id: string;
  name: string | null;
  status: DataRetentionPolicyStatus;
  type: DataRetentionPolicyType;
  version: number;
  createdDate: string;
  updatedDate: string | null;
  createdBy: string | null;
  products: DataRetentionProductResponse[];
  previousVersion: DataRetentionDetailsResponse | null;
  dataRetentionPolicyApprovalRequests: DataRetentionPolicyApprovalResponse[];
  isEditable?: boolean; // temporary solution, before BE updates
};

export type DataRetentionCategoryPeriodOption = {
  type: DataRetentionProductRuleType;
  defaultValue: number;
  isDefault: boolean;
};

export type DataRetentionCategoryResponse = {
  category: string | null;
  description: string | null;
  order: number;
  types: DataRetentionCategoryPeriodOption[] | null;
};

export type DataRetentionVersionResponse = {
  id: string;
  createdBy: string | null;
  reviewedBy: string | null;
  policyStatus: DataRetentionPolicyStatus;
  approvalStatus: DataRetentionPolicyApprovalStatus;
  version: number;
  createdDate: string;
  reviewDate: string | null;
};

export type DataRetentionVersionPagedResponse = {
  totalCount: number;
  items: DataRetentionVersionResponse[];
};

export type AvailableDataRetentionProduct = {
  productId: string;
  productName: string;
  schemes: DataRetentionSchemeResponse[] | null;
  dataRetentionCategories: DataRetentionCategoryResponse[] | null;
};

export type DataRetentionProductRuleDto = {
  dataRetentionCategory: string;
  type: DataRetentionProductRuleType;
  period: number;
};

export type DataRetentionProductDto = {
  order: number;
  productId: string;
  schemeIds: string[];
  dataRetentionProductRules: DataRetentionProductRuleDto[];
};

export type CreateDataRetentionDto = {
  companyId: string;
  name: string;
  dataRetentionProducts: DataRetentionProductDto[];
};

export type UpdatePolicyNameDataRetentionDto = {
  id: string;
  companyId: string;
  name: string;
};

export type UpdateDataRetentionDto = {
  id: string;
  companyId: string;
  creatorComment?: string;
  dataRetentionProducts: DataRetentionProductDto[];
};

export enum DataRetentionAuditStatus {
  InProgress = 'InProgress',
  Succeed = 'Succeed',
  Failed = 'Failed',
}

export type DataRetentionAuditResponse = {
  id: string;
  status: DataRetentionAuditStatus;
  message: string | null;
  scheme: string | null;
  product: string | null;
  category: string | null;
  exception: string | null;
  startDate: string;
  endDate: string | null;
};

export type DataRetentionAuditPagedResponse = {
  totalCount: number;
  items: DataRetentionAuditResponse[];
};

export type UserSearchSFTPInfoResponse = {
  id: string;
  companyId: string;
  productId: string;
  firstName: string | null;
  lastName: string | null;
  companyName: string | null;
  productName: string | null;
  storageAccountName: string | null;
  containerName: string | null;
  localUserName: string | null;
  sftPlogin: string | null;
};

export type UserSearchSFTPInfoResponsePagedResponse = {
  totalCount: number;
  items: UserSearchSFTPInfoResponse[] | null;
};

export type UserCompanyAllSchemesAccessResponse = {
  id: string;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
};

export type UserCompanyAllSchemesAccessResponsePagedResponse = {
  totalCount: number;
  items: UserCompanyAllSchemesAccessResponse[];
};

export enum CompanyDefaultSchemeAccessAction {
  DisabledFeature = 'DisabledFeature',
  EnabledFeature = 'EnabledFeature',
  RemovedAccess = 'RemovedAccess',
  GrantedAccess = 'GrantedAccess',
}

export type DefaultSchemeAccessAuditResponse = {
  id: string;
  changedByUserFullName: string | null;
  action: CompanyDefaultSchemeAccessAction;
  date: string;
  affectedUserEmail: string | null;
};

export type DefaultSchemeAccessAuditResponsePagedResponse = {
  totalCount: number;
  items: DefaultSchemeAccessAuditResponse[];
};

export type EnvironmentOptionsResponse = {
  id: string;
  isPublishConfigurationEnabled: boolean;
};

export type UpdateEnvironmentOptionsModel = {
  isPublishConfigurationEnabled: boolean;
};

export type EnvironmentConfigurationProductResponse = {
  productId: string;
  productName: string | null;
};

export type EnvironmentConfigurationResponse = {
  id: string;
  name: string | null;
  dataGatewayUrl: string | null;
  clientId: string | null;
  secretCode: string | null;
  environmentConfigurationProducts: EnvironmentConfigurationProductResponse[] | null;
};

export type EnvironmentConfigurationProductModel = {
  productId: string;
};

export type EnvironmentConfigurationModel = {
  id?: string;
  name: string;
  dataGatewayUrl: string;
  clientId: string;
  secretCode: string;
  environmentConfigurationProducts: EnvironmentConfigurationProductModel[];
};

export type TestConnectionResponse = {
  productId: string;
  productName: string | null;
  isSuccess: boolean;
  errorMessage: string | null;
};
