import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { useDispatch as useDispatchOriginal, useSelector } from 'react-redux';

import { resetStore } from './slices/storeSlice';
import authReducer from './slices/authSlice';
import companyReducer from './slices/companySlice';
import routerReducer from './slices/routerSlice';
import tenantReducer from './slices/tenantSlice';
import userReducer from './slices/userSlice';
import productInfoReducer from './slices/productInfoSlice';
import dataRetentionReducer from './slices/dataRetentionSlice';
import breadcrumbsReducer from './slices/breadcrumbsSlice';
import adminContactReducer from './slices/adminContact';

const rootReducer = combineReducers({
  auth: authReducer,
  company: companyReducer,
  router: routerReducer,
  user: userReducer,
  productInfo: productInfoReducer,
  tenant: tenantReducer,
  dataRetention: dataRetentionReducer,
  breadcrumbs: breadcrumbsReducer,
  adminContact: adminContactReducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

export type RootState = ReturnType<typeof rootReducer>;
export type StoreDispatch = typeof store.dispatch;

export const useDispatch = () => useDispatchOriginal<StoreDispatch>();
export { useSelector, resetStore };

export default store;
